import { IoWifi } from "react-icons/io5";


export default function NotConection({handleClosed}){
    return(
        <div className='cont-not-conection w-100'>
            <div className="row w-100 text-center justify-center">
                <IoWifi className="icn_wifi_not"/>
                <div className="mb-2">
                    <h4 className="tt_avis_not">No hay conexion a internet</h4>
                    <p className="text_ul_sm">Estas acciones solo las puedes realizar con internet.</p>
                </div>
                <button className="btn btn-primary waves-effect waves-light" onClick={handleClosed}>Ok</button>
            </div>
        </div>
    )
}