import {useState, useEffect} from 'react'
import Select from 'react-select'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {separarImagen} from "../utility/utils"
import {IoChevronBack } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import Compress from 'compress.js'


export default function AuthorForm({open, setOpen, isComplete, authorId, type, setMessage, idSucursal, handleClosed}) {
    const {online, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [image, setImage] = useState("/images/usuario.png");
    const [image_f, setImagef] = useState(null);
    const [s_file, setSFile] = useState(null);
    const reader = new FileReader();
	const [author, setAuthor] = useState({
        name: "",
        nickname: "",
        stage_name: "",
        phone: "",
        email: "",
        birth: "",
        birth_city: "",
        address: "",
        ocupation: "",
        marital_status: "Soltero",
        rfc: "",
        author_society: "",
        member_id: "",
        ipi_name_number: "",
        curp: ""
    });
    const options = [
        { value: 'ASCAP', label: 'ASCAP' },
        { value: 'BMI', label: 'BMI' },
        { value: 'SACM', label: 'SACM' },
        { value: 'SESAC', label: 'SESAC' }
    ]

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (author.name != ""){
            	let response = null;
                let formData = new FormData();
                formData.append('name', author.name);
                formData.append('nickname', author.nickname);
                formData.append('stage_name', author.stage_name);
                formData.append('phone', author.phone);
                formData.append('email', author.email);
                formData.append('birth', author.birth);
                formData.append('birth_city', author.birth_city);
                formData.append('address', author.address);
                formData.append('ocupation', author.ocupation);
                formData.append('marital_status', author.marital_status);
                formData.append('rfc', author.rfc);
                formData.append('member_id', author.member_id);
                formData.append('ipi_name_number', author.ipi_name_number);
                formData.append('curp', author.curp);
                if (author.author_society.length > 0) {
                    for (let i = 0; i < author.author_society.length; i++) {
                        formData.append('author_society[]', author.author_society[i]);
                    }
                }
                setLoading(true);
                button.disabled = true;
	            if (authorId) {
	            	response = await fetch(urlbase+"/app/update_author/"+authorId, {
		                method: 'PUT',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_author", {
		                method: 'POST',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito") {
                    if (authorId) {
                        setMessage({text: type+" actualizado", type:"done"});
                    }else{
                        setMessage({text: type+" guardado", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setAuthor({
                        name: "",
                        nickname: "",
                        stage_name: "",
                        phone: "",
                        email: "",
                        birth: "",
                        birth_city: "",
                        address: "",
                        ocupation: "",
                        marital_status: "Soltero",
                        rfc: "",
                        author_society: "",
                        member_id: "",
                        ipi_name_number: "",
                        curp: ""
                    });
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa al menos el nombre");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadAuthor = async (id) => {
    	const res = await fetch(urlbase+"/app/get_author/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.author;
        setAuthor({
            name: data.name,
            nickname: data.nickname,
            stage_name: data.stage_name,
            phone: data.phone,
            email: data.email,
            birth: data.birth,
            birth_city: data.birth_city,
            address: data.address,
            ocupation: data.ocupation,
            marital_status: data.marital_status,
            rfc: data.rfc,
            author_society: data.author_society,
            member_id: data.member_id,
            ipi_name_number: data.ipi_name_number,
            curp: data.curp
        });
        if (data.photo) {
            setImage(urlbase+data.photo);
        }
        setIsLoad(false);
    }
    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleChange = (e) => {
        setAuthor({...author, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 800,maxHeight: 800,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
                setSFile(true);
            }
        });
    }
    const handleMulti = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setAuthor({...author, ['author_society']: arr});
        setAlert(null);
    }

    
    useEffect(() => {
        if (open) {
            setAuthor({
                name: "",
                nickname: "",
                stage_name: "",
                phone: "",
                email: "",
                birth: "",
                birth_city: "",
                address: "",
                ocupation: "",
                marital_status: "Soltero",
                rfc: "",
                author_society: "",
                member_id: "",
                ipi_name_number: "",
                curp: ""
            });
            setAlert(null);
            if (authorId != null){
                setIsLoad(true);
                loadAuthor(authorId);
            }else{
                setIsLoad(false);
                setImage("/images/usuario.png");
            }
        }
    }, [open]);

    useEffect(() => {
        if (s_file) {
            setAuthor({...author, ['photo']: image_f});
        }
    }, [image_f]);

    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{authorId != null ? 'Editar '+type : 'Nuevo '+type}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Nombre completo</label>
                        <input type="text" className="inp_add text-uppercase" value={author.name} onChange={handleChange} name="name" placeholder="Alfonso De La Cruz Garcia" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 my-1 w-100">
                        <div className="col">
                            <label className="lb-inp-usm mb-1">Seudónimo</label>
                            <input type="text" className="inp_add text-uppercase" value={author.nickname} onChange={handleChange} name="nickname" placeholder="Alfonso De La Cruz" autoComplete="off"/>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1">Nombre en composiciones</label>
                            <input type="text" className="inp_add text-uppercase" value={author.stage_name} onChange={handleChange} name="stage_name" placeholder="Alfonso De La Cruz" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Telefono (opcional)</label>
                        <input type="text" className="inp_add" value={author.phone} onChange={handleChange} name="phone" placeholder="Telefono" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 my-1 w-100">
                        <label className="lb-inp-usm">Correo (opcional)</label>
                        <input type="text" className="inp_add" value={author.email} onChange={handleChange} name="email" placeholder="Correo" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Fecha de nacimiento</label>
                        <input type="date" className="inp_add" value={author.birth} onChange={handleChange} name="birth" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 w-100 my-1">
                        <label className="lb-inp-usm">Ciudad de nacimiento</label>
                        <input type="text" className="inp_add" value={author.birth_city} onChange={handleChange} name="birth_city" placeholder='Guadalajara' autoComplete="off"/>
                    </div>
                    <div className="row gap-1 w-100">
                        <label className="lb-inp-usm">Direccion</label>
                        <input type="text" className="inp_add" value={author.address} onChange={handleChange} name="address" placeholder='Paseo de la hacienda #430' autoComplete="off"/>
                    </div>
                    <div className="row gap-1 my-1 w-100">
                        <label className="lb-inp-usm">Ocupacion</label>
                        <input type="text" className="inp_add" value={author.ocupation} onChange={handleChange} placeholder='Productor, Musico, Compositor' name="ocupation" autoComplete="off"/>
                    </div>
                    <div className="row gap-1 w-100">
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">Estado civil</label>
                            <select className="inp_add clear_sl_p" value={author.marital_status} name="marital_status" onInput={handleChange}>
                                <option value="Soltero">Soltero</option>
                                <option value="Casado">Casado</option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">RFC</label>
                            <input type="text" className="inp_add" value={author.rfc} onChange={handleChange} name="rfc" placeholder="XFD344SS" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row gap-1 my-1 w-100">
                        <label className="lb-inp-usm mb-1">Sociedad Autoral</label>
                        <div className='position-relative w-100'>
                            <Select className='multi_sel' 
                                name="author_society" 
                                placeholder={"elegir..."} 
                                onChange={handleMulti} 
                                options={options}
                                value={author.author_society && author.author_society.length > 0 ? author.author_society.map((value) => {return {value: value, label:value}}) : ""}
                                isMulti
                                />
                        </div>
                    </div>
                    <div className="row gap-1 w-100">
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">Member id</label>
                            <input type="text" className="inp_add" value={author.member_id} onChange={handleChange} name="member_id" placeholder="00236563" autoComplete="off"/>
                        </div>
                        <div className="col">
                            <label className="lb-inp-usm mb-1 w-100">IPI name number</label>
                            <input type="text" className="inp_add" value={author.ipi_name_number} onChange={handleChange} name="ipi_name_number" placeholder="322599663" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row gap-1 my-1  w-100">
                        <label className="lb-inp-usm">Curp</label>
                        <input type="text" className="inp_add text-uppercase" value={author.curp} onChange={handleChange} name="curp" placeholder='' autoComplete="off"/>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1 w-100">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{authorId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}