import {useState, useEffect, useRef} from 'react'
import {IoAddSharp, IoSend, IoEllipsisVerticalSharp, IoCreate, IoRemoveCircle, IoChevronBack, IoLogoWhatsapp, IoChevronForwardSharp } from "react-icons/io5";
import Modal from "../../components/ui/Modal";
import OptionMovil from "../../components/ui/OptionMovil";
import EditoraForm from "../../components/forms/EditoraForm"
import SnackBar from "../../components/ui/SnackBar";
import Editora from "../../components/views/Editora"
import ProgressIntern from "../../components/ui/ProgressIntern";
import {searchTable} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import { updateLocalEditoras, getEditoras } from "../../db";

export default function Editoras() {
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const [editoras, setEditoras] = useState([]);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [openp, setOpenp] = useState(false);
    const [openv, setOpenv] = useState(false);
    const [idview, setIdView] = useState(null);
    const [opend, setOpend] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [ideditora, setIdEditora] = useState(null);
    const tableSer = useRef(null);

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const abrirModal = () => {
        setOpen(true);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpend(false);
        setOpenp(false);
        setIdRef(null);
        setOpenv(null);
        setIdView(null);
        setIdEditora(null);
        e.stopPropagation();
    }
    const loadEditoras = async () => {
        if (online) {
            try {
                setIdRef(null);
                setIdEditora(null);
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_editoras", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                setEditoras(data);
                setIsLoad(true);
                setLoading(false);
                await updateLocalEditoras(data);
            } catch (err){
                console.log(err);
                console.warn('Fallo al obtener datos online, usando datos offline');
                const data = await getEditoras();
                setEditoras(data);
                setIsLoad(true);
                setLoading(false);
            }
        } else {
            console.warn('Sin conexión, usando datos offline');
            const data = await getEditoras();
            setEditoras(data);
            setIsLoad(true);
            setLoading(false);
        }
    }
    const delEditora = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_editora/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            setEditoras(editoras.filter((editora) => editora._id !== idref));
            if (data.clave == 'exito') {
                setMessage({text: "Editora eliminada", type:"error"});
            }else{
                setMessage({text: data.mensaje, type:"error"});
            }
            setLoading(false);
            setOpend(false);
            setIdRef(null);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleEdit = async (e) => {
        setIdEditora(e.currentTarget.id);
        setOpen(true);
    }
    const handleOptions = async (e) => {
        setIdRef(e.currentTarget.id);
        setIdEditora(e.currentTarget.id);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenv(true);
    }

    useEffect(() => {
        loadEditoras();
        setIsLoad(false);
    }, [])


    if (!isload) return <div className="Isexpand">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div>
            <div className="fadeIn px-3">
                <div className="row w-100 sm-py-3"></div>
                <div className="row justify-between align-items-center">
                    <div className="col-2 ocultar-sm">
                        <h1 className="title-lg my-1">Editoras</h1>
                    </div>
                    <div className="col-4 col-sm-9 py-2">
                        <input type="search" className="inp_add" placeholder="Buscar editora..." onInput={handleSearch} onClick={handleSelect}/>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Editora</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th className="ocultar-sm">Encargado</th>
                                <th>Whatsapp</th>
                                <th className="ocultar-sm">Correo</th>
                                <th className="text-center">Acc</th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {editoras.map((editora, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td>
                                        <div className="row gap-1 justify-items-center" id={editora._id} onClick={handleView}>
                                            <div className="col-1">
                                                <img src={urlbase+'/images/editora.png'} className="img-profile-sm" />
                                            </div>
                                            <div className="col text-left">
                                                <p className="tag_inl is_link clear-my">{editora.name}</p>
                                                <p className="stx_tt text-secondary sm-text clear-my">{editora.city}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="ocultar-sm">
                                        <img src={'/images/usuario.png'} className="img-profile-sm mr-1" />
                                        <p className="tag_inl is_link clear-my ocultar-sm">{editora.encargado.name}</p>
                                    </td>
                                    <td>
                                        <a className="tag-phone" target='_blank' href={"https://wa.me/"+editora.encargado.whatsapp+"/?text=¡Hola buen dia!"}>
                                            <IoLogoWhatsapp className="icon-whats-tg"/>
                                            <span className="mx-1 phone-number ocultar-sm">{editora.encargado.phone}</span>
                                            <IoChevronForwardSharp className="icon-whats-tg"/>
                                        </a>
                                    </td>
                                    <td className="ocultar-sm">
                                        <a className="tag-phone" href={"mailto:"+editora.encargado.email}>
                                            <IoSend className="icon-phone-tg"/>
                                            <span className="mx-1 phone-number ocultar-sm">{editora.encargado.email}</span>
                                        </a>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={editora._id} onClick={handleEdit}>Editar</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={editora._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={editora._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <EditoraForm setOpen={setOpen} isComplete={loadEditoras} editoraId={ideditora} refName="usuario" setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={openv} setOpen={setOpenv} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <Editora open={openv} setOpen={setOpenv} editoraId={idview} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Desea eliminar esta editora?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body">
                    <p className="color-red">La editora se eliminara permanentemente de la aplicacion</p>
                </div>
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delEditora}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption} setIdClient={setIdEditora} >
                <ul className="list">
                    <div className="item-list waves-effect" id={ideditora} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">Editar</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}