import {useState, useEffect, useRef} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {useNavigate} from 'react-router-dom'
import {limitText} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import Select from 'react-select'
import { deleteSong, saveToIndexedDB } from "../../db";
import {IoChevronBack, IoCloseCircle, IoCheckmark, IoCloudUploadOutline, IoRefreshCircle, IoSettingsSharp, IoArrowDownCircleOutline, IoArrowDownCircleSharp} from "react-icons/io5";


export default function LiberarForm({open, setOpen, isComplete, cancionId, isAcc, setMessage, handleClosed}) {
    const {online, session, loading, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [takeDown, setTakeDown ] = useState(false);
    const [isload, setIsLoad] = useState(true);
    const [progress, setProgress] = useState(0);
    const [authors, setAuthors] = useState([]);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [isFailed, setFailed] = useState(true);
    const [selectes, setSelectes] = useState([]);
    const file_p_demo = useRef();
    const file_c_reg = useRef();
    const reader = new FileReader();
    const navigate = useNavigate();
	const [song, setSong] = useState({
        title: "",
        authors:"",
        cover_file: "",
        folio_reg: "",
        coauthor: "",
        perc_parti: "",
        demo_track: "",
        song_lyrics: ""
    });
    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            window.scrollTo(0,0);
            file_p_demo.current.value = '';
            file_c_reg.current.value = '';
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.demo_track != "") {
                let formData = new FormData();
                if (isAcc == 'Liberar_cero') {
                    formData.append('title', song.title);
                    formData.append('track', song.track);
                    if (selectes.length > 0) {
                        for (let i = 0; i < selectes.length; i++) {
                            formData.append('autors[]', selectes[i]);
                        }
                    }
                }
                formData.append('folio_reg', song.folio_reg);
                formData.append('coauthor', song.coauthor);
                formData.append('perc_parti', song.perc_parti);
                formData.append('demo_track', song.demo_track);
                // formData.append('certificate', song.certificate);
                formData.append('song_lyrics', song.song_lyrics);
                formData.append('accion', isAcc);
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                Req.open("PUT", urlbase+"/app/update_song_libre/"+cancionId, true);
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        const sgnDB = res.song;
                        if(res.clave && res.clave == "exito") {
                            await deleteSong(sgnDB._id+'Creada', song.title);
                            if (takeDown){
                                const blob = new Blob([song.demo_track], { type: song.demo_track.type });
                                await saveToIndexedDB(sgnDB._id+'Libre', song.title, 'Libre', blob);
                            }
                            if (isAcc == 'Liberar_edit') {
                                setMessage({text: "Tema actualizado con exito!", type:"done"});
                                isComplete('f_d');
                            }else{
                                if (isAcc == 'Liberar_cero') {
                                    setMessage({text: "Tema agregado con exito!", type:"done"});
                                    isComplete('f_d');
                                }else{
                                    setMessage({text: "Tema liberado con exito!", type:"done"});
                                    setTimeout(function() {
                                        navigate("/app/catalogo/libres");
                                    }, 1000);
                                }
                            }
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            setSong({ title: "", authors:"", folio_reg:"", demo_track: "", coauthor:"", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
                            window.scrollTo(0,0);
                            setIsLoad(true);
                            file_p_demo.current.value = '';
                            file_c_reg.current.value = '';
                            handleClosed();
                        }else{
                            setAlert(res.mensaje);
                            setLoading(false);
                            button.disabled = false;
                        }
                    }
                }
                if (isAcc == 'Liberar_cero' && song.title == '') {
                    setAlert("Debes completar el titulo de la cancion");
                    setLoading(false);
                    button.disabled = false;
                }else{
                    Req.send(formData);
                }
            }else{
            	setAlert("Debes agregar la pista demo");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        if (isAcc == 'Liberar_edit') {
            setSong({ title: data.title, song_lyrics: data.song_lyrics, folio_reg:data.folio_reg, coauthor:data.coauthor, perc_parti:data.perc_parti, authors:data.authors, state: data.state});
        }else{
            setSong({...song, title: data.title, song_lyrics: data.song_lyrics, authors:data.authors, state: data.state});
        }
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
        setIsLoad(false);
        file_p_demo.current.value = '';
        file_c_reg.current.value = '';
    }
    const loadAuthors = async (id) => {
    	const res = await fetch(urlbase+"/app/get_authors", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response;
        setAuthors(data);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['demo_track']: e.target.files[0]});
        }else{
            setSong({...song, ['demo_track']: null});
        }
    }
    const handleTrack = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    }
    const handleDown = (e) => {
        if (e.currentTarget) {
            setTakeDown(e.currentTarget.checked);
        }
    }
    const handleMulti = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectes(arr);
        setAlert(null);
    }

    useEffect(() => {
        if (open) {
            setSong({ title: "", authors:"", demo_track: "", folio_reg:"", track: "", coauthor:"", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
            setAlert(null);
            if (cancionId != null){
                setIsLoad(true);
                loadSong(cancionId);
            }else{
                loadAuthors();
                setIsLoad(false);
                setImage("/images/caratula.jpg");
            }
        }
    }, [open]);


    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{isAcc == 'Liberar' ? 'Liberar Tema' : isAcc == 'Liberar_edit' ? 'Editar Liberacion' : isAcc == 'Liberar_cero' ? 'Crear y Liberar' : '-'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                {isAcc == 'Liberar_cero' ? <div className="row gap-1 w-100 mb-2">
                    <div className='row gap-1 w-100'>
                        <div className="col-1">
                            <IoSettingsSharp className="icon-md"/>
                        </div>
                        <div className="col text-left">
                            <strong className="tag_inl clear-my text-uppercase sm-text is_link">Datos de creación</strong>
                        </div>
                    </div>
                    <div className="row border-form p-2 w-100">
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>Titulo</span>
                                    <span className='tg-requir'>obligatorio</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder="Titulo original" autoComplete="off"/>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Autor</label>
                            <Select className='multi_sel w-100'
                                name="autors"
                                placeholder={"Elegir autores"}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMulti}
                                options={authors.map((author, ind) => {
                                    return {value:author._id, label:author.stage_name}
                                })}
                            />
                        </div>
                        <div className="row w-100 ">
                            <label className="lb-inp-usm mb-1">Pista de audio</label>
                            <input type="file" className="inp_add" onInput={handleTrack} name="track" accept=".mp3,audio/"/>
                        </div>
                    </div>
                </div> : ''}
                <div className="row gap-1 w-100">
                    {isAcc == 'Liberar' || isAcc == 'Liberar_edit' ? <div className="row w-100">
                        <label className="lb-inp-usm mb-1">TEMA</label>
                        <a className="tag-phone f_imp row justify-between justify-items-center w-100">
                            <div className="col">
                                <img src={image != '/images/caratula.jpg' ? image : urlbase+'/images/caratula.jpg'} className="img-profile-sm sm-img-med mt-p3 mr-1" />
                                <p className="tag_inl inl_bk_sm clear-my">
                                    <div className='is_link'>{limitText(song.title, 20)}</div>
                                    <div className='sm-text-75 text-secondary'>{song.authors}</div>
                                </p>
                            </div>
                        </a>
                    </div>: ''}
                    <div className="row gap-1 w-100">
                        <div className="col">
                            <label className="lb-inp-usm mb-1">Folio de registro</label>
                            <input type="text" className="inp_add text-uppercase" value={song.folio_reg} onChange={handleChange} name="folio_reg" placeholder="FL656556565655 (opcional)" autoComplete="off"/>
                        </div>
                        <div className="col-5">
                            <label className="lb-inp-usm mb-1">Participacion</label>
                            <input type="number" min="1" className="inp_add" value={song.perc_parti} onChange={handleChange} name="perc_parti" placeholder="50% (opcional)" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1 w-100 my-1">
                            <div className='row w-100 justify-between'>
                                <span>Pista demo *</span>
                                {isAcc == 'Liberar_edit' ? '' : <span className='tg-requir'>obligatorio</span>}
                            </div>
                        </label>
                        <input type="file" ref={file_p_demo} className={ isAcc == 'Liberar_edit' ? "inp_add" : "inp_add in-requi" } onInput={handleMp3} name="demo_track" accept=".mp3,audio/"/>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">Letra</label>
                        <textarea className="inp_add h-200" onChange={handleChange} value={song.song_lyrics} name="song_lyrics" placeholder="Letra de la cancion (opcional)"></textarea>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
            </div>
            <div className="modal_footer">
                <div className="d-flex justify-end gap-2">
                    <div>
                        <label className={takeDown ? "custom-checkbox custom-is_check" : "custom-checkbox"} for='downloadCheckbox'>
                            <input type="checkbox" id="downloadCheckbox" onChange={handleDown} />
                            <span className="spn_chk">Mantener descarga</span>
                            {takeDown ? <IoArrowDownCircleSharp className="icon-download" /> : <IoArrowDownCircleOutline className="icon-download" />}
                        </label>
                    </div>
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{loading ? 'SUBIENDO...': isAcc == 'Liberar_edit' ? 'Aplicar' : 'Liberar'}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">SUBIENDO...</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">Pista de audio y Poster</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">Error al subir los archivos, mala conexion</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">No cierres esta ventana hasta que se complete la subida de archivos</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}