import {useState, useEffect, useRef} from 'react'
import {IoEllipsisVerticalSharp, IoRemoveCircle, IoArrowRedoSharp, IoRadioOutline, IoPlaySharp, IoSend, IoChevronBack, IoAddSharp} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import ApartarForm from "../../components/forms/ApartarForm"
import ProgressIntern from "../../components/ui/ProgressIntern";
import Song from "../../components/views/Song";
import {useNavigate} from 'react-router-dom'
import {searchTable, extractDateFormatR, extractHour} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import { updateLocalSharings, getSharings } from "../../db";


export default function Sharing({type}){
    const {online, position, play, setPlay, setPosition, playlist, setPlaylist, setLoading, urlbase, myHeaders} = useApp();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [sharings, setSharings] = useState([]);
    const [songs, setSongs] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [opendown, setOpendown] = useState(false);
    const [opend, setOpend] = useState(false);
    const [opendel, setOpendel] = useState(false);
    const [opens, setOpenes ] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idclient, setIdClient] = useState(null);
    const [idview, setIdView] = useState(null);
    const [s_client, setSClient] = useState(null);
    const [accion, setAccion] = useState('Apartar');
    const tableSer = useRef(null);
    const navigate = useNavigate();
    const section = 'Sharing';

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpend(false);
        setOpendel(false);
        setOpenes(false);
        setIdRef(null);
        setIdClient(null);
        setSClient(null);
        setIdView(null);
        setOpendown(false);
        setAccion('Apartar');
        e.stopPropagation();
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }
    const handleApartar = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpen(true);
        let id_client = e.currentTarget.attributes.getNamedItem('data-client').value;
        setSClient(id_client);
    }
    const handlePlay = async (e) => {
        if (sharings.length > 0) {
            setPlaylist(songs);
            setPosition(Number(e.currentTarget.id));
            setPlay(true);
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const loadSharings = async () => {
        if (online) {
            try {
                setIdRef(null);
                setIdClient(null);
                setSClient(null);
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_sharings", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                var shars = data.sharings;
                setSharings(shars);
                if (shars && shars.length > 0) {
                    setSongs(shars.map((shar) => {
                        shar.song.state = 'Apartada';
                        return shar.song;
                    }));
                }
                setIsLoad(true);
                setLoading(false);
                await updateLocalSharings(shars);
            } catch (err){
                console.log(err);
                console.warn('Fallo al obtener datos online, usando datos offline');
                const data = await getSharings();
                if (data && data.length > 0) {
                    setSongs(data.map((shar) => {
                        shar.song.state = 'Apartada';
                        return shar.song;
                    }));
                }
                setSharings(data);
                setIsLoad(true);
                setLoading(false);
            }
        } else {
            console.warn('Sin conexión, usando datos offline');
            const data = await getSharings();
            if (data && data.length > 0) {
                setSongs(data.map((shar) => {
                    shar.song.state = 'Apartada';
                    return shar.song;
                }));
            }
            setSharings(data);
            setIsLoad(true);
            setLoading(false);
        }
    }
    const delSharing = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_sharing/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setSharings(sharings.filter((sharingdb) => sharingdb._id !== idref));
                setMessage({text: "Envio eliminado", type:"error"});
                setOpendel(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpendel(true);
    }
    const handleOptions = async (e) => {
        var id_song = e.currentTarget.attributes.getNamedItem('data-song').value;
        var id_client = e.currentTarget.attributes.getNamedItem('data-client').value;
        setIdRef(e.currentTarget.id);
        setIdClient(id_song);
        setSClient(id_client);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }

    useEffect(() => {
        setSharings([]);
        setSongs([]);
        setPlay(false);
        setMessage(false);
        loadSharings();
        setIsLoad(false);
    }, [type])

    useEffect(() => {
        console.log(songs);
    }, [songs])


    if (!isload) return <div className="Isexpand w-100">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn w-100">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-1">
                    <div className="col-5 col-5 col-sm-9">
                        <div className="row w-100 justify-items-center gap-2">
                            <div className="col">
                                <input type="search" className="inp_add" placeholder="Buscar enviadas..." onInput={handleSearch} onClick={handleSelect}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov">
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Nueva</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th className="text-center">Pista</th>
                                <th>Titulo</th>
                                <th className="ocultar-sm">Enviada</th>
                                <th className="ocultar-sm">Otros clientes</th>
                                <th className="ocultar-sm">Apartar</th>
                                <th className="text-center">Acc</th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {sharings.map((send, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn_play waves-effect is_shar" id={ind+1} onClick={handlePlay}>{
                                            position == ind+1 && play == true
                                            ?  <IoRadioOutline className="icon-ply pulse" /> 
                                            : <IoPlaySharp className="icon-ply" />}
                                        </button>
                                    </td>
                                    <td>
                                        <div className="row gap-1 justify-items-center">
                                            <div className="col-1">
                                                <img src={send.song && send.song.cover_file != null ? urlbase+send.song.cover_file : urlbase+'/images/caratula.jpg'} className="img-profile-sm" />
                                            </div>
                                            <div className="col text-left">
                                                <div className='w-100 showMovil'><span className="date-mv color-secondary">{extractDateFormatR(send.created)}</span> <span className="date-mv color-secondary">{extractHour(send.created)}</span></div>
                                                <p className="tag_inl is_link clear-my" id={send.song ? send.song._id : ''} onClick={handleView}>{send.song && send.song.title ? send.song.title : '-'}</p>
                                                    <p className="text-secondary sm-text-75 clear-my">
                                                        <div className="row w-100 justify-items-center">
                                                            <IoSend className="icon-btn sm-text mr-m" /> 
                                                            <span>{send.client ? send.client.name : '-'}</span>
                                                        </div>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="ocultar-sm">
                                        <span className="sm-text">{extractDateFormatR(send.created)}</span> <span className="sm-text color-secondary">{extractHour(send.created)}</span>
                                    </td>
                                    <td className="ocultar-sm">
                                        <p className="tag_inl is_link clear-my ocultar-sm sm-text">{send.client ? send.client.name : ' - '} y 5 mas</p>
                                    </td>
                                    <td className='ocultar-sm'>
                                        <button className="btn btn-sm btn-primary ocultar-sm" data-client={send.client ? send.client._id : ''} id={send.song ? send.song._id : ''} onClick={handleApartar}>
                                            <div className="row w-100 align-items-center">
                                                <IoArrowRedoSharp className="icon-btn mr-m"/>
                                                <span className="col">Apartar</span>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={send._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" data-client={send.client ? send.client._id : ''} data-song={send.song ? send.song._id : ''} id={send._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview}/>
            </Modal>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <ApartarForm open={open} setOpen={setOpen} isComplete={loadSharings} isAcc={accion} cancionId={idclient} idClient={s_client} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={opendel} setOpen={setOpendel} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Eliminar este envio?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">Este envio se eliminara permanentemente de la aplicacion</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delSharing}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idclient} onClick={handleApartar}>
                        <div className="cont-icon">
                            <IoArrowRedoSharp className="icon_item"/>
                        </div>
                        <span className="tex_item">Apartada</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}