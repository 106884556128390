import {capitalizeWords} from "../utility/utils";

export default function AutorsField({autors}){
    return(
        <>
            <span className="color-secondary">{
            autors && autors.length > 0 ? autors.map((aut, ind) =>  ind+1 != autors.length 
                ? capitalizeWords(aut.stage_name)+", " 
                : capitalizeWords(aut.stage_name))
            : ''}</span>
        </>
    )
}