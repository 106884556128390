import './App.css';
import 'node-waves/dist/waves.css'
import 'react-widgets/styles.css';
import { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { Route, Routes } from 'react-router-dom';
import Waves from 'node-waves'
import Dashboard from './layouts/Dashboard';
import Redactor from './layouts/Redactor';
import Login from './pages/Login';
import Tablon from './pages/admin/Tablon';
import Clientes from './pages/admin/Clientes';
import Artistas from './pages/admin/Artistas';
import Creaciones from './pages/admin/Creaciones';
import Catalogo from './pages/admin/Catalogo';
import Usuarios from './pages/admin/Usuarios';
import Authors from './pages/admin/Authors';
import PerfilUpdate from './pages/admin/PerfilUpdate'
import Editoras from './pages/admin/Editoras'
import MoreOptions from './pages/admin/MoreOptions';
import Sharing from './pages/admin/Sharing'
import ListOfReg from './pages/admin/ListOfReg'
import {AppProvider} from './context/appContext';
import ProtectedPublic from "./components/protected/ProtectedPublic"

function App() {
    const location = useLocation();
    useEffect(() => {
      let config = {
        duration: 600
      }
      Waves.attach('.button', ['waves-effect', 'waves-float']);
      Waves.init(config);
      // Registra el Service Worker
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
              console.log('Service Worker registrado con éxito:', registration);
              // Escucha cuando haya una nueva versión
              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === "installed") {
                    if (navigator.serviceWorker.controller) {
                      // Mostrar notificación
                      const shouldReload = window.confirm(
                        "Nueva versión disponible. ¿Quieres recargar la aplicación?"
                      );
                      if (shouldReload) {
                        window.location.reload();
                      }
                    } else {
                      console.log("Contenido almacenado para uso offline.");
                    }
                  }
                };
              };
            })
            .catch((error) => {
              console.error('Error al registrar el Service Worker:', error);
            });
        });
      }
    })
    useEffect(() => {
      window.scrollTo(0,0);
    }, [location]);
    
    return (

      <AppProvider>

        <Routes>
          <Route path="/" element={ <ProtectedPublic> <Login></Login> </ProtectedPublic>}></Route>
          <Route path="app" element={<Dashboard />}>
            <Route index element={<Tablon></Tablon>} />
            <Route path="tablon" element={<Tablon></Tablon> } />
            <Route path="creaciones" element={<Creaciones></Creaciones> } />
            <Route path="catalogo" element={<Catalogo type={"Libres"}></Catalogo>} />
            <Route path="catalogo/libres" element={<Catalogo type={"Libres"}></Catalogo>} />
            <Route path="catalogo/apartadas" element={<Catalogo type={"Apartadas"}></Catalogo>} />
            <Route path="catalogo/vendidas" element={<Catalogo type={"Vendidas"}></Catalogo>} />
            <Route path="usuarios" element={<Usuarios></Usuarios>} />
            <Route path="clientes" element={<Clientes></Clientes>} />
            <Route path="artistas" element={<Artistas></Artistas>} />
            <Route path="autores" element={<Authors></Authors>} />
            <Route path="editoras" element={<Editoras></Editoras>} />
            <Route path="listas_de_registros" element={<ListOfReg></ListOfReg>} />
            <Route path="enviadas" element={<Sharing></Sharing>} />
            <Route path="more_options" element={<MoreOptions></MoreOptions>} />
            <Route path="foto_perfil" element={<PerfilUpdate></PerfilUpdate>} />
          </Route>
          <Route path="editor" element={<Redactor/>} >
            <Route index element={<Tablon></Tablon>} />
            <Route path="tablon" element={<Tablon></Tablon> } />
            <Route path="catalogo" element={<Catalogo type={"Libres"}></Catalogo>} />
            <Route path="catalogo/libres" element={<Catalogo type={"Libres"}></Catalogo>} />
            <Route path="catalogo/apartadas" element={<Catalogo type={"Apartadas"}></Catalogo>} />
            <Route path="catalogo/vendidas" element={<Catalogo type={"Vendidas"}></Catalogo>} />
            <Route path="listas_de_registros" element={<ListOfReg></ListOfReg>} />
            <Route path="autores" element={<Authors></Authors>} />
            <Route path="foto_perfil" element={<PerfilUpdate></PerfilUpdate>} />
          </Route>
          <Route path="*" element={<h1>No Encontrado</h1>}></Route>
        </Routes>

      </AppProvider>
    );
}

export default App;
