import { openDB } from 'idb';

const DB_NAME = 'SongorIDB';
const DB_VERSION = 1;

// Lista de stores
const STORES = {
  SONGS: 'songs',
  AUTHORS: 'authors',
  EDITORAS: 'editoras',
  ARTISTAS: 'artistas',
  CLIENTES: 'clientes',
  CREACIONES: 'creaciones',
  LIBRES: 'libres',
  APARTADAS: 'apartadas',
  GRABADAS: 'grabadas',
  USUARIOS: 'usuarios',
  ENVIADAS: 'enviadas'
};

export const initDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      // Crear store para canciones
      if (!db.objectStoreNames.contains(STORES.SONGS)) {
        db.createObjectStore(STORES.SONGS, { keyPath: 'id' });
      }
      // Crear store para autores
      if (!db.objectStoreNames.contains(STORES.AUTHORS)) {
        db.createObjectStore(STORES.AUTHORS, { keyPath: '_id' });
      }
      // Crear store para autores
      if (!db.objectStoreNames.contains(STORES.EDITORAS)) {
        db.createObjectStore(STORES.EDITORAS, { keyPath: '_id' });
      }
      // Crear store para autores
      if (!db.objectStoreNames.contains(STORES.ARTISTAS)) {
        db.createObjectStore(STORES.ARTISTAS, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.CLIENTES)) {
        db.createObjectStore(STORES.CLIENTES, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.CREACIONES)) {
        db.createObjectStore(STORES.CREACIONES, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.LIBRES)) {
        db.createObjectStore(STORES.LIBRES, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.APARTADAS)) {
        db.createObjectStore(STORES.APARTADAS, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.GRABADAS)) {
        db.createObjectStore(STORES.GRABADAS, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.USUARIOS)) {
        db.createObjectStore(STORES.USUARIOS, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(STORES.ENVIADAS)) {
        db.createObjectStore(STORES.ENVIADAS, { keyPath: '_id' });
      }

    },
  });
  return db;
};


// Función genérica para manejar stores
const getStore = async (storeName, mode = 'readonly') => {
  const db = await initDB();
  return db.transaction(storeName, mode).objectStore(storeName);
};


// Funciones para guardar los blobs de canciones
export const saveToIndexedDB = async (id, title, state, blob) => {
    try {
        const db = await initDB();
        await db.put(STORES.SONGS, { id, title, state, blob });
        console.log(`Canción "${title}" guardada exitosamente.`);
    } catch (error) {
        console.error("Error al guardar en IndexedDB:", error);
    }
};
export const getSongFromIndexedDB = async (id) => {
    try {
        const db = await initDB();
        const song = await db.get(STORES.SONGS, id);
        return song ? song.blob : null;
    } catch (error) {
        console.error("Error al leer de IndexedDB:", error);
        return null;
    }
};
export const deleteSong = async (id, title) => {
  try {
    const db = await initDB();
    await db.delete(STORES.SONGS, id);
    console.log(`Canción "${title}" eliminada de descargas.`);
  } catch (error) {
      console.error("Error al leer de IndexedDB:", error);
      return null;
  }
};
export const clearSongs = async () => {
  const db = await initDB();
  await db.clear(STORES.SONGS);
};

/**
 * Funciones para manejar las creaciones
 */
export const updateLocalCreaciones = async (songs) => {
  try {
    const store = await getStore(STORES.CREACIONES, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const song of songs) {
        await store.put(song);
    }
    await store.done;
    console.log(`Creaciones actualizadas exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las creaciones en IndexedDB:', error);
  }
};
export const getCreacion = async (id) => {
  try {
    const store = await getStore(STORES.CREACIONES);
    const song = await store.get(id);
    return song || null;
  } catch (error) {
    console.error('Error al obtener el la creacion de IndexedDB:', error);
    return null;
  }
};
export const getCreaciones = async () => {
  try {
    const store = await getStore(STORES.CREACIONES);
    const songs = await store.getAll(); // Obtiene todos los registros
    return songs || [];
  } catch (error) {
    console.error('Error al obtener las creaciones de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar las Libres
 */
export const updateLocalLibres = async (songs) => {
  try {
    const store = await getStore(STORES.LIBRES, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const song of songs) {
        await store.put(song);
    }
    await store.done;
    console.log(`Libres actualizadas exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las libres en IndexedDB:', error);
  }
};
export const getLibre = async (id) => {
  try {
    const store = await getStore(STORES.LIBRES);
    const song = await store.get(id);
    return song || null;
  } catch (error) {
    console.error('Error al obtener el las libres de IndexedDB:', error);
    return null;
  }
};
export const getLibres = async () => {
  try {
    const store = await getStore(STORES.LIBRES);
    const songs = await store.getAll(); // Obtiene todos los registros
    return songs || [];
  } catch (error) {
    console.error('Error al obtener las libres de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar las Apartadas
 */
export const updateLocalApartadas = async (songs) => {
  try {
    const store = await getStore(STORES.APARTADAS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const song of songs) {
        await store.put(song);
    }
    await store.done;
    console.log(`Apartadas actualizadas exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las Apartadas en IndexedDB:', error);
  }
};
export const getApartada = async (id) => {
  try {
    const store = await getStore(STORES.APARTADAS);
    const song = await store.get(id);
    return song || null;
  } catch (error) {
    console.error('Error al obtener el las apartadas de IndexedDB:', error);
    return null;
  }
};
export const getApartadas = async () => {
  try {
    const store = await getStore(STORES.APARTADAS);
    const songs = await store.getAll(); // Obtiene todos los registros
    return songs || [];
  } catch (error) {
    console.error('Error al obtener las apartadas de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar las Grabadas
 */
export const updateLocalGrabadas = async (songs) => {
  try {
    const store = await getStore(STORES.GRABADAS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const song of songs) {
        await store.put(song);
    }
    await store.done;
    console.log(`Grabadas actualizadas exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las grabadas en IndexedDB:', error);
  }
};
export const getGrabada = async (id) => {
  try {
    const store = await getStore(STORES.GRABADAS);
    const song = await store.get(id);
    return song || null;
  } catch (error) {
    console.error('Error al obtener el las grabadas de IndexedDB:', error);
    return null;
  }
};
export const getGrabadas = async () => {
  try {
    const store = await getStore(STORES.GRABADAS);
    const songs = await store.getAll(); // Obtiene todos los registros
    return songs || [];
  } catch (error) {
    console.error('Error al obtener las grabadas de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar las ENVIADAS
 */
export const updateLocalSharings = async (sharings) => {
  try {
    const store = await getStore(STORES.ENVIADAS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const sharing of sharings) {
        await store.put(sharing);
    }
    await store.done;
    console.log(`Sharings actualizadas exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las sharings en IndexedDB:', error);
  }
};
export const getSharing = async (id) => {
  try {
    const store = await getStore(STORES.ENVIADAS);
    const sharing = await store.get(id);
    return sharing || null;
  } catch (error) {
    console.error('Error al obtener el las sharings de IndexedDB:', error);
    return null;
  }
};
export const getSharings = async () => {
  try {
    const store = await getStore(STORES.ENVIADAS);
    const sharings = await store.getAll(); // Obtiene todos los registros
    return sharings.reverse() || [];
  } catch (error) {
    console.error('Error al obtener las sharings de IndexedDB:', error);
    return null;
  }
};



/**
 * Funciones para manejar autores
 */
export const updateLocalAuthors = async (authors) => {
  try {
    const store = await getStore(STORES.AUTHORS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const author of authors) {
        await store.put(author);
    }
    await store.done;
    console.log(`Autores actualizados exitosamente.`);
  } catch (error) {
    console.error('Error al guardar el autor en IndexedDB:', error);
  }
};
export const getAuthor = async (id) => {
  try {
    const store = await getStore(STORES.AUTHORS);
    const author = await store.get(id);
    return author || null;
  } catch (error) {
    console.error('Error al obtener el autor de IndexedDB:', error);
    return null;
  }
};
export const getAuthors = async () => {
  try {
    const store = await getStore(STORES.AUTHORS);
    const authors = await store.getAll(); // Obtiene todos los registros
    return authors.reverse() || [];
  } catch (error) {
    console.error('Error al obtener el autor de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar editoras
 */
export const updateLocalEditoras = async (editoras) => {
  try {
    const store = await getStore(STORES.EDITORAS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const editora of editoras) {
        await store.put(editora);
    }
    await store.done;
    console.log(`Editoras actualizados exitosamente.`);
  } catch (error) {
    console.error('Error al guardar las editoras en IndexedDB:', error);
  }
};
export const getEditora = async (id) => {
  try {
    const store = await getStore(STORES.EDITORAS);
    const editora = await store.get(id);
    return editora || null;
  } catch (error) {
    console.error('Error al obtener la editora de IndexedDB:', error);
    return null;
  }
};
export const getEditoras = async () => {
  try {
    const store = await getStore(STORES.EDITORAS);
    const editoras = await store.getAll(); // Obtiene todos los registros
    return editoras.reverse() || [];
  } catch (error) {
    console.error('Error al obtener las editora de IndexedDB:', error);
    return null;
  }
};

/**
 * Funciones para manejar artistas
 */
export const updateLocalArtistas = async (artistas) => {
  try {
    const store = await getStore(STORES.ARTISTAS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const artista of artistas) {
        await store.put(artista);
    }
    await store.done;
    console.log(`Artistas actualizados exitosamente.`);
  } catch (error) {
    console.error('Error al guardar los artistas en IndexedDB:', error);
  }
};
export const getArtistas = async () => {
  try {
    const store = await getStore(STORES.ARTISTAS);
    const artistas = await store.getAll(); // Obtiene todos los registros
    return artistas.reverse() || [];
  } catch (error) {
    console.error('Error al obtener los artistas de IndexedDB:', error);
    return null;
  }
};


/**
 * Funciones para manejar clientes
 */
export const updateLocalClientes = async (clientes) => {
  try {
    const store = await getStore(STORES.CLIENTES, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const cliente of clientes) {
        await store.put(cliente);
    }
    await store.done;
    console.log(`Clientes actualizados exitosamente.`);
  } catch (error) {
    console.error('Error al guardar los clientes en IndexedDB:', error);
  }
};
export const getCliente = async (id) => {
  try {
    const store = await getStore(STORES.CLIENTES);
    const cliente = await store.get(id);
    return cliente || null;
  } catch (error) {
    console.error('Error al obtener el cliente de IndexedDB:', error);
    return null;
  }
};
export const getClientes = async () => {
  try {
    const store = await getStore(STORES.CLIENTES);
    const clientes = await store.getAll(); // Obtiene todos los registros
    return clientes.reverse() || [];
  } catch (error) {
    console.error('Error al obtener los clientes de IndexedDB:', error);
    return null;
  }
};


/**
 * Funciones para manejar usuarios
 */
export const updateLocalUsuarios = async (usuarios) => {
  try {
    const store = await getStore(STORES.USUARIOS, 'readwrite');
    // Limpia y guarda los datos nuevos
    await store.clear();
    for (const usuario of usuarios) {
        await store.put(usuario);
    }
    await store.done;
    console.log(`Usuarios actualizados exitosamente.`);
  } catch (error) {
    console.error('Error al guardar los usuarios en IndexedDB:', error);
  }
};
export const getUsuarios = async () => {
  try {
    const store = await getStore(STORES.USUARIOS);
    const usuarios = await store.getAll(); // Obtiene todos los registros
    return usuarios.reverse() || [];
  } catch (error) {
    console.error('Error al obtener los usuarios de IndexedDB:', error);
    return null;
  }
};