import {useState, useEffect, useRef} from 'react'
import {IoAddSharp, IoEllipsisVerticalSharp, IoCreate, IoRemoveCircle, IoReaderSharp, IoChevronBack} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import ListForm from "../../components/forms/ListForm"
import List from "../../components/views/List"
import ProgressIntern from "../../components/ui/ProgressIntern";
import NotConection from "../../components/ui/NotConection";
import {limitText, searchTable} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";

export default function ListOfReg({type}){
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [lists, setLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [opend, setOpend] = useState(false);
    const [openv, setOpenv] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idlist, setIdList] = useState(null);
    const tableSer = useRef(null);

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setOpenv(false);
            setAlert(null);
        }, 200);
    }
    const abrirModal = () => {
        setOpen(true);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpenv(false);
        setOpend(false);
        setIdRef(null);
        setIdList(null);
        e.stopPropagation();
    }
    const loadLists = async () => {
        try {
            setIdRef(null);
            setIdList(null);
            setLoading(true);
            let prms = '';
            const response = await fetch(urlbase+"/app/get_list_of_regs", {
                method: 'GET',
                headers: myHeaders(),
            })
            const data = await response.json();
            setLists(data);
            setIsLoad(true);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const delList = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_list/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setLists(lists.filter((lst) => lst._id !== idref));
                setMessage({text: "Lista eliminada", type:"error"});
                setOpend(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleEdit = async (e) => {
        setIdList(e.currentTarget.id);
        setOpen(true);
    }
    const handleOptions = async (e) => {
        setIdRef(e.currentTarget.id);
        setIdList(e.currentTarget.id);
        setOption(true);
    }
    const handleView = async (e) => {
        setIdList(e.currentTarget.id);
        setOpenv(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }

    useEffect(() => {
        setLists([]);
        setMessage(false);
        loadLists();
        setIsLoad(false);
    }, [type]);

    if (!online) return <div className="fadeIn">
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;


    if (!isload) return <div className="Isexpand">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-3">
                    <div className="col-2 ocultar-sm">
                        <h1 className="title-lg my-1">Registro</h1>
                    </div>
                    <div className="col-4 col-sm-9 py-2">
                        <input type="search" className="inp_add" placeholder="Buscar..." onInput={handleSearch} onClick={handleSelect}/>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Registro</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th th className="text-center">List</th>
                                <th th className="text-center">Cert.</th>
                                <th className="ocultar-sm text-center">Canciones</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {lists.map((list, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td><p className="tag_inl is_link clear-my" onClick={handleView} id={list._id}>{list.name}</p></td>
                                    <td className="text-center ocultar-sm">
                                        {list.file && list.file != '' ? <a className="tag-phone" href={urlbase+list.file} target="_blank">
                                            <IoReaderSharp  className="icon-phone-tg"/>
                                        </a> : <span className="tag-phone opc-5" target="_blank">
                                                <IoReaderSharp  className="icon-phone-tg"/>
                                            </span>}
                                    </td>
                                    <td className="text-center ocultar-sm">
                                        {list.certificate && list.certificate != '' ? <a className="tag-phone" href={urlbase+list.certificate} target="_blank">
                                            <IoReaderSharp  className="icon-phone-tg"/>
                                        </a> : <span className="tag-phone opc-5" target="_blank">
                                                <IoReaderSharp  className="icon-phone-tg"/>
                                            </span>}
                                    </td>
                                    <td className='text-center'>{list.songs}</td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={list._id} onClick={handleEdit}>Editar</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={list._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={list._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <ListForm open={open} setOpen={setOpen} isComplete={loadLists} listId={idlist} type={'List'} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={openv} setOpen={setOpenv} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <List open={openv} setOpen={setOpenv} listId={idlist} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Eliminar esta lista?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">Esta lista se eliminara permanentemente de la aplicacion</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delList}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption} setIdClient={setIdList}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idlist} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">Editar</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}