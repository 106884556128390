import {useState, useEffect} from 'react'
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoMusicalNoteSharp } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import {extractHour, extractDateR} from "../../components/utility/utils";
import { getAuthor } from "../../db";

export default function Author({authorId, setOpen}){
    const {online, urlbase, myHeaders} = useApp();
    const [isload, setIsLoad] = useState(true);
    const [author, setAuthor] = useState({
        name: "",
        nickname: "",
        phone: "",
        email: "",
        birth: "",
        address: "",
        ocupation: "",
        marital_status: "Soltero",
        rfc: "",
        author_society: "",
        member_id: "",
        ipi_name_number: "",
        curp: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setIsLoad(true);
        }, 200);
    }
    const loadAuthor = async (id) => {
        if (online) {
            const res = await fetch(urlbase+"/app/get_author/"+id, {
                method: 'GET',
                headers:myHeaders()
            })
            const response = await res.json();
            const data = response.author;
            setAuthor(data);
            setIsLoad(false);
        }else{
            let data = await getAuthor(id);
            setAuthor(data);
            setIsLoad(false);
        }
    }

    useEffect(() => {
        if (authorId) {
            loadAuthor(authorId);
        }
    }, [authorId]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


    return(
        <div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>INFO AUTOR</h5>
            </div>
            <div className='modal_body'>
                <div className="row w-100">
                    <div className="row justify-center w-100">
                        <div className='w-100 sm-py-2'></div>
                        <div className='w-100 sm-py-2'></div>
                        <div className="col-8 text-center col-sm-12">
                            <img src={urlbase+'/images/usuario.png'} className="img-profile" />
                            <div className='row w-100'>
                                <div className='w-100 text-center my-1'>
                                    <span className='tg-state-apa'>
                                        <IoMusicalNoteSharp className="icn-sate" />
                                        {author.ocupation}
                                    </span>
                                </div>
                                <h2 className="w-100">{author.name}</h2>
                                <p className="text-secondary clear-my w-100 mb-2">{author.nickname}</p>
                            </div>
                            <div className='w-100 py-1'></div>
                            <div className='row w-100'>
                                <div className="list-ficha w-100 px-1 md-p-2 text-left">
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Ciudad de nacimiento</strong>
                                        <span className="col text-right">{author.birth_city}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Fecha de nacimiento</strong>
                                        <span className="col text-right">{author.birth}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">CREADO</strong>
                                        <span className="col text-right text-secondary">{(extractDateR(author.created))} {extractHour(author.created)}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">MODIFICADO</strong>
                                        <span className="col text-right text-secondary">{(extractDateR(author.modified))} {extractHour(author.modified)}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Curp</strong>
                                        <span className="col text-right text-secondary">{author.curp}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Correo</strong>
                                        <span className="col text-right is_link text-secondary">{author.email}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Telefono</strong>
                                        <span className="col text-right is_link text-secondary">{author.phone}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Estado civil</strong>
                                        <span className="col text-right text-secondary">{author.marital_status}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">RFC</strong>
                                        <span className="col text-right is_link">{author.rfc}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Direccion</strong>
                                        <span className="col text-right">{author.address}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Member id</strong>
                                        <span className="col text-right is_link">{author.member_id}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">IPI name number</strong>
                                        <span className="col text-right is_link">{author.ipi_name_number}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Fecha de nacimiento</strong>
                                        <span className="col text-right is_link">{author.birth}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Sociedad autoral</strong>
                                        <span className="col text-right">{author.author_society ? author.author_society.toString() : ''}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 py-3'></div>
        </div>
    )
}