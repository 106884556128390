import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import CroppieImage from "../ui/CroppieImage";
import {separarImagen} from "../utility/utils"
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import AccordionEditHead from "../../components/ui/AccordionEditHead";
import {IoChevronBack } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import Compress from 'compress.js'


export default function ArtistForm({open, setOpen, isComplete, artistId, type, setMessage, idSucursal, handleClosed}) {
    const {online, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [image, setImage] = useState("/images/usuario.png");
    const [image_f, setImagef] = useState(null);
    const [s_file, setSFile] = useState(null);
    const reader = new FileReader();
	const [artist, setArtist] = useState({
        name: "",
        phone: "",
        email: "",
        photo: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (artist.name != ""){
            	let response = null;
                let formData = new FormData();
                formData.append('name', artist.name);
                formData.append('phone', artist.phone);
                formData.append('email', artist.email);
                formData.append('photo', artist.photo);
                if (idSucursal){
                    formData.append('branch', idSucursal);
                }
                setLoading(true);
                button.disabled = true;
	            if (artistId) {
	            	response = await fetch(urlbase+"/app/update_artist/"+artistId, {
		                method: 'PUT',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_artist", {
		                method: 'POST',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (artistId) {
                        setMessage({text: type+" actualizado", type:"done"});
                    }else{
                        setMessage({text: type+" guardado", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setArtist({ name: "", phone: "", email: "", photo: "" });
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa al menos nombre");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadClient = async (id) => {
    	const res = await fetch(urlbase+"/app/get_artist/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.artist;
        setArtist({ name: data.name, photo:data.photo, phone: data.phone, email: data.email});
        if (data.photo) {
            setImage(urlbase+data.photo);
        }
        setIsLoad(false);
    }
    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleChange = (e) => {
        setArtist({...artist, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 800,maxHeight: 800,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
                setSFile(true);
            }
        });
    }

    useEffect(() => {
        if (open) {
            setArtist({ name: "", phone: "", email: "", photo: "" });
            setAlert(null);
            if (artistId != null){
                setTimeout(() => {
                    setIsLoad(true);
                    loadClient(artistId);
                }, 400);
            }else{
                setIsLoad(false);
                setImage("/images/usuario.png");
            }
        }
    }, [open]);

    useEffect(() => {
        if (s_file) {
            setArtist({...artist, ['photo']: image_f});
        }
    }, [image_f]);

    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{artistId != null ? 'Editar '+type.toLowerCase() : 'Nuevo '+type.toLowerCase()}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    {open ? <Accordion className='accordion cl-mg-top w-100' transition={true} transitionTimeout={250}>
                        <AccordionItem header={<AccordionEditHead titulo={'Foto de perfil'} initialEntered={false}/>} >
                            {({state}) => (<>
                                <div className="row w-100">
                                    {state.isEnter ? <CroppieImage open={state.isEnter} image={image} aspect={4 / 4} getCompleteCorp={completeImage} /> : ''}
                                    <div className="row my-1 text-center w-100">
                                        <button className="btn btn-primary w-100">Elegir foto
                                            <input type="file" className="file-in-button" onChange={handleChangeFile} accept="image/*"/>
                                        </button>
                                    </div>
                                </div>
                            </>)}
                        </AccordionItem>
                    </Accordion> : ''}
                    <div className="row w-100 my-2">
                        <div className="row gap-1 w-100">
                            <label className="lb-inp-usm">Nombre completo</label>
                            <input type="text" className="inp_add text-uppercase" value={artist.name} onChange={handleChange} name="name" placeholder="Nombre *" autoComplete="off"/>
                        </div>
                        <div className="row gap-1 my-1 w-100 dnone">
                            <label className="lb-inp-usm">Telefono</label>
                            <input type="text" className="inp_add" value={artist.phone} onChange={handleChange} name="phone" placeholder="Telefono" autoComplete="off"/>
                        </div>
                        <div className="row gap-1 my-1 w-100 dnone">
                            <label className="lb-inp-usm">Correo (opcional)</label>
                            <input type="text" className="inp_add" value={artist.email} onChange={handleChange} name="email" placeholder="Correo" autoComplete="off"/>
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{artistId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}