import {IoMusicalNoteOutline, IoMusicalNotesSharp, IoMusicalNotes, IoMusicalNoteSharp, IoChevronForwardOutline, IoSend} from "react-icons/io5";
import {useState, useEffect} from 'react'
import {Link } from "react-router-dom";
import {useApp} from "../../context/appContext";
import Modal from "../../components/ui/Modal";
import Song from "../../components/views/Song";
import {extractHour, extractDateFormatR} from "../../components/utility/utils"
import {IoChevronForward, IoClose, IoCheckmark} from "react-icons/io5";
import { getCreaciones, getLibres, getApartadas, getGrabadas } from "../../db";

export default function Tablon(){
    const {online, session, setLoading, urlbase, myHeaders} = useApp();
    const [access, setAccess] = useState([]);
    const [sends, setSends] = useState([]);
    const [songs, setSongs] = useState([]);
    const [opens, setOpenes ] = useState(false);
    const [idview, setIdView] = useState(null);
    const [statistics, setStatistics] = useState({
        creaciones: 0,
        libres: 0,
        apartadas: 0,
        grabadas: 0
    });

    const handleClosed = (e) => {
        setOpenes(false);
        setIdView(null);
        e.stopPropagation();
    }
    const loadStatistics = async (date) => {
        if (online) {
            try {
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_statistics", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                const access = data.accesos;
                const sends = data.eviados;
                setStatistics(data.datos);
                if (access && access.length > 0) {
                    setAccess(access);
                }
                if (sends && sends.length > 0) {
                    setSends(sends);
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }else{
            const t_creaciones = await getCreaciones();
            const t_libres = await getLibres();
            const t_apartadas = await getApartadas();
            const t_grabadas = await getGrabadas();
            setStatistics({
                creaciones: t_creaciones.length,
                libres: t_libres.length,
                apartadas: t_apartadas.length,
                grabadas: t_grabadas.length
            });
            setLoading(false);
        }
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }

    useEffect(() => {
        loadStatistics();
    }, []);



    return(
        <div className="Isexpand sm-py-3">
            <div className="row my-2 w-100">
                <div className="wrapper-4 sm-wrapper-2 w-100 px-3">
                    {session && session.user.rol != 'Redactor' ? <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.creaciones}</h1>
                        <Link className="row is_link w-100 justify-center justify-align-items-center" to="/app/creaciones">
                            <h2 className="title-crd-t clear-m-p">Creaciones </h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div> : ''}
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.libres}</h1>
                        <Link className="row w-100 is_link justify-center justify-align-items-center" to="/app/catalogo/libres">
                            <h2 className="title-crd-t clear-m-p">Libres </h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.apartadas}</h1>
                        <Link className="row w-100 is_link justify-center justify-align-items-center" to={session && session.user.rol != 'Redactor' ? "/app/catalogo/apartadas" : "/editor/catalogo/apartadas"}>
                            <h2 className="title-crd-t clear-m-p">Apartadas </h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.grabadas}</h1>
                        <Link className="row w-100 is_link justify-center justify-align-items-center" to={session && session.user.rol != 'Redactor' ? "/app/catalogo/vendidas" : "/editor/catalogo/vendidas"}>
                            <h2 className="title-crd-t clear-m-p">Grabadas </h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                </div>  
            </div>
            {session && session.user.rol != 'Redactor' ? <div className="row px-3 gap-3">
                <div className="col col-sm-12 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">Enviadas recientemente</h3>
                            <table className="table_outline">
                                <thead>
                                    <tr>
                                        <th className="text-center ocultar-sm">Folio</th>
                                        <th>Artista</th>
                                        <th className="text-right">Enviada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sends.map((send, ind) => {
                                        if (send.song && send.song._id && send.client) {
                                            return(<tr key={'t_u_'+ind}>
                                                <td className="text-center ocultar-sm">
                                                    {send.song && send.song.folio_reg ? send.song.folio_reg : '-'}
                                                </td>
                                                <td>
                                                    <div className="row gap-1 justify-items-center">
                                                        <div className="col text-left">
                                                            <p className="tag_inl is_link clear-my" id={send.song._id} onClick={handleView}>{send.song ? send.song.title : '-'}</p>
                                                            <p className="text-secondary sm-text-75 clear-my">
                                                                <div className="row w-100 justify-items-center">
                                                                    <span>{send.client ? send.client.name : '-'}</span>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <span className="sm-text w-100">{extractDateFormatR(send.created)}</span>
                                                    <p className="w-100 sm-text color-secondary w-100">{extractHour(send.created)}</p>
                                                </td>
                                            </tr>)
                                        }
                                    })}
                                </tbody>
                            </table>
                            <Link to={'/app/enviadas'} className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">Ver todos</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col col-sm-12 md-p-2 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">Accesos al sistema</h3>
                            <table className="table_outline">
                                <tbody>
                                    {access.map((acc) => {
                                        let d = new Date(acc.date_in);
                                        return(
                                            <tr key={acc._id}>
                                                <td className="ocultar-sm">
                                                    <span className="ocultar-md text-secondary fs-85">{extractDateFormatR(d)+" "+extractHour(d)}</span> 
                                                </td>
                                                <td>
                                                    {acc.user != null ? <div className="row gap-1 justify-items-center">
                                                        <div className="col-2 mr-1">
                                                            <img src="/images/usuario.png" className="img-profile-sm" />
                                                        </div>
                                                        <div className="col text-left">
                                                            <p className="tag_inl is_link clear-my">{acc.user.name} {acc.user.lastname}</p>
                                                            <p className="stx_tt text-secondary sm-text ocultar-sm clear-my">{acc.user.rol}</p>
                                                            <span className="showMovil text-secondary fs-85">{extractDateFormatR(d)+" "+extractHour(d)}</span>
                                                        </div>
                                                    </div> :
                                                    <p className="is_link clear-my">Desconocido</p>}
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        {acc.login ? 
                                                            <span className="mx-1 sm-text tag-done sm-text ocultar-sm"><IoCheckmark className="icon-sm align-icon"/> exitoso</span> 
                                                            : <span className="mx-1 sm-text tag-fail sm-text ocultar-sm"><IoClose className="icon-sm align-icon"/> fallido</span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">usuario </span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.code}</span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">contraseña </span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.password}</span>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">Ver todos</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
            </div> : ''}
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview}/>
            </Modal>
        </div>
    )
}