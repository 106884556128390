import {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";

export default function MovilMenu({open, items, children, animation, online}){
    let [elements, setElements] = useState([]);

    useEffect(() => {
        setElements(items);
    }, []);

    return(
        <div className={"fixed-bottom-menu bg-light"} id="menu_slide">
            <div className="position-sticky">
                {children}
                <ul className={items && items.length == 5 ? "wrapper-5 justify-center" : "wrapper-4 justify-center"}>
                    {items.map((item, ind) => {
                        return (
                            <NavLink key={'men_'+ind} to={item.url} className={({isActive}) => (isActive ? 'waves-effect nav-link-m isActive' : 'waves-effect nav-link-m')}>
                                {item.icon}
                                <span className="tex_hed_m">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
                <div className='w-100 p-menu-mov'></div>
                {online ? '' : (<div className='no-inline'>Modo sin conexión</div>) }
            </div>
        </div>
    )
}