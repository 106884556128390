import { useEffect } from "react";
import {IoListSharp, IoClose} from "react-icons/io5";
import DynamicAudioPlayer from '../../components/ui/DynamicAudioPlayer';
// import "react-h5-audio-player/lib/styles.css";
import { useApp } from "../../context/appContext";


export default function TrackPlayer({track, playlist, setPlaylist}) {
    const {play, setPlay, position, setPosition, urlbase} = useApp();

    const handleBackSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == 1) {
                setPosition(playlist.length);
            }else{
                setPosition(prevPos => prevPos - 1);
            }
        }
    }
    const handlenextSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == playlist.length) {
                setPosition(1);
            }else{
                setPosition(prevPos => prevPos + 1);
            }
        }
    }
    const handlePlay = (e) => {
        setPlay(true);
    }
    const handlePause = (e) => {
        setPlay(true);
    }
    const handleEnd = (e) => {
        handlenextSong();
    }
    const handleClose = (e) => {
        setTimeout(() => {
            setPlay(false);
            setPlaylist([]);
            setPosition(0);
        }, 200);
    }
    

    useEffect(() => {
        
    }, [playlist])

    return(
        <div className={"fixed-play-track slideInUp"} id="track_comp">
            <button className='btn_track btn_closed_track waves-effect text-center' onClick={handleClose}>
                <IoClose className='icon-md' />
            </button>
            <div className="row w-100 justify-center">
                <div className='row w-rep justify-center'>
                    {
                        playlist.length > 0 && playlist[position-1] ?
                            (
                                <div style={{ width: "100%", margin: "auto" }}>
                                    <DynamicAudioPlayer
                                        playlist={playlist}
                                        autoPlay={true}
                                        showJumpControls={false} // Ocultamos controles estándar de adelantar/retroceder
                                        showVolumeControl={false} // Ocultamos el control de volumen
                                        onEnded={handleEnd}
                                        onPlay={handlePlay} // Evento play
                                        onPause={handlePause} // Evento pause
                                    />
                                </div>
                        ) : ''
                    }
                </div>
            </div>
            <div className='col text-left dnone'>
                <button className='btn_track waves-effect text-center'>
                    <IoListSharp className='icon-med' />
                </button>
            </div>
        </div>
    )
}