import html2canvas from "html2canvas";
import {useState, useEffect, useRef} from 'react'
import {useApp} from "../../context/appContext";
import {extractDateR, extractHour} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";
import {IoImagesOutline, IoPrint} from "react-icons/io5";
import { getCreacion, getLibre, getApartada, getGrabada } from "../../db";


export default function FichaPrint({open, songId, vaucher, estado}){
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const [isload, setIsLoad] = useState(true);
    const imageCont = useRef(null);
    const [song, setSong] = useState({
        final_title: "",
        title: "",
        authors:"",
        track: "",
        metadata: null,
        state: "Creada",
        creation_date: new Date(),
        song_lyrics: "",
        cover_file: "",
        folio_reg: "",
        coauthor: "",
        perc_parti: "",
        demo_track: "",
        certificate: "",
        requested: "",
        end_time_requested: "",
        interpreter: "",
        master_recording: "",
        perc_autor: "",
        perc_coautor: "",
        perc_editor: "",
        editor: "",
        contract: "",
        type_date_contract: 'Infinita',
        contract_date_start: "",
        contract_date_end: "",
        author_society: ""
    });
    const loadSong = async (id) => {
        if (online) {
            const res = await fetch(urlbase+"/app/get_song/"+id, {
                method: 'GET',
                headers:myHeaders()
            })
            const response = await res.json();
            const data = response.song;
            setSong(data);
            setIsLoad(false);
        }else{
            if (estado == 'Creacion') {
                let data = await getCreacion(id);
                setSong(data);
            }else if (estado == 'Libre') {
                let data = await getLibre(id);
                setSong(data);
            }else if (estado == 'Apartada') {
                let data = await getApartada(id);
                setSong(data);
            }else if(estado == 'Grabada'){
                let data = await getGrabada(id);
                setSong(data);
            }else{
                let data1 = await getCreacion(id);
                let data2 = await getLibre(id);
                let data3 = await getApartada(id);
                let data4 = await getGrabada(id);
                if (data1) {
                    setSong(data1);
                }else if(data2) {
                    setSong(data2);
                }else if(data3) {
                    setSong(data3);
                }else if(data4) {
                    setSong(data4);
                }
            }
            setIsLoad(false);
        }
    }
    const downloadImage = async () => {
        if (imageCont.current) {
            const canvas = await html2canvas(imageCont.current);
            const image = canvas.toDataURL("image/png", 1.0);
            const link = window.document.createElement("a");
            link.style = "display:none;";
            link.download = 'Ficha_songOr_'+song.title;
            link.href = image;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
        }
    }
    const showPrint = async () => {
        window.print();
    }

    useEffect(() => {
        if (songId) {
            if (open) {
                setIsLoad(true);
                loadSong(songId);
            }
        }else{
            setIsLoad(false);
        }
    }, [open]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
        <div className="py-2"></div>
        <div className="py-2"></div>
        <div className="py-2"></div>
        <div className="py-2"></div>
        <div className="py-2"></div>
        <div className="py-2"></div>
    </div>;


    return(
        <div className="row w-100 bg-white rounded">
            <div className="row w-100 justify-start">
                <div className="row px-3 w-70 no_print">
                    <div className="row w-100 justify-center py-2">
                        <button className="btn btn-sm btn-primary mr-1" onClick={downloadImage}>
                            <IoImagesOutline className="mr-1"/>
                            <span>descargar</span>
                        </button>
                        <button className="btn btn-sm btn-warning mr-1" onClick={showPrint}>
                            <IoPrint className="mr-1"/>
                            <span>imprimir</span>
                        </button>
                    </div>
                </div>
                <div className="row w-100 justify-start my-2 position-relative overflow-hidden" ref={imageCont}>
                    <img src="/images/icon.png" className="aqua-mark" />
                    <div className="py-1 row justify-between gap-3 justify-items-center w-85-f">
                        <div className="col-2 col-sm-12 sm-text-center">
                            <img src="/images/icon.png" className="img-ticket" />
                        </div>
                        <div className="col text-left">
                            <div className="row w-100">
                                <div className="row w-100">
                                    <h2 className="tt-ticket w-100 my-1 mb-0">Ficha de Canción</h2>
                                    <p className="text-dec_h text-secondary p-1">Paseo de la hacienda 410 Mazatlan, Sinaloa. alfonsodelacruzpay89@outlook.com telefono: 669-271-0225</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="limpio">
                                <strong className="limpio">
                                    <span className="mx-1 phone-number ocultar-sm">Creada</span>
                                </strong>
                                <p className="limpio text-secondary">
                                    <span className="mx-1 sm-text ocultar-sm">{song ? extractDateR(song.creation_date) : '-'}</span>
                                </p>
                            </div>
                            <div className="limpio">
                                <strong className="limpio">
                                    <span className="mx-1 phone-number ocultar-sm">Impresa</span>
                                </strong>
                                <p className="limpio text-secondary">
                                    <span className="mx-1 sm-text ocultar-sm">{(extractDateR(new Date()))} {extractHour(new Date())}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-1 row justify-between justify-items-center gap-2 w-85-f">
                        <div className="col-7 col-sm-12 text-left">
                            <div className="card-white row">
                                <div className="row w-100 gap-2 p-2">
                                    <div className="col-5 col-sm-5 text-left">
                                        <img src='/images/caratula.jpg' className="img-profile sm-w-100" />
                                    </div>
                                    <div className="col text-left">
                                        <div className="limpio w-100">
                                            <span>Titulo</span>
                                            <h3>{song && song.title ? song.title : '-'}</h3>
                                        </div>
                                        <div className="limpio w-100">
                                            <span>Folio</span>
                                            <h3>{song && song.folio_reg ? song.folio_reg : '-'}</h3>
                                        </div>
                                        <div className="limpio w-100">
                                            <span>Fecha de Lanzamiento</span>
                                            <h3>04-09-2024</h3>
                                        </div>
                                        <div className="limpio w-100">
                                            <span>Estado</span>
                                            <h3>{song && song.state ? song.state : '-'}</h3>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col col-sm-12 text-left">
                            <div className="card-white row">
                                <div className="row w-100 p-2">
                                    <div className="tt_ficha_sg w-100 mb-1 bg_grab">Grabación</div>
                                    <div className="limpio w-100">
                                        <span>Titulo final</span>
                                        <h3>{song.final_title ? song.final_title : '-'}</h3>
                                    </div>
                                    <div className="limpio w-100">
                                        <span>Editora</span>
                                        <h3>{song.editor && song.editor.name ? song.editor.name : '-'}</h3>
                                    </div>
                                    <div className="limpio w-100">
                                        <span>Fecha de grabación</span>
                                        <h3>{song && song.end_time_requested ? extractDateR(song.end_time_requested) : ''}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-1 w-100"></div>
                    <div className="row mb-1 justify-between w-85-f">
                        <div className="tt_ficha_sg w-100">Compositores</div>
                        <div className="row p-1 justify-between w-100">
                            <div className="limpio text-secondary">
                                { song && song.autors && song.autors.length > 0 ? 
                                    song.autors.map((aut, ind) => 
                                        (<div className="row w-100 gap-3 rw-cm-t">
                                            <div className="limpio"><strong></strong>{aut.stage_name}</div>
                                            {aut.member_id ? <div className="limpio"><strong>Member ID:</strong>{aut.member_id}</div> : ''}
                                            {aut.ipi_name_number ? <div className="limpio"><strong>Ipi Number:</strong>{aut.ipi_name_number}</div> : ''}
                                        </div>))
                                : ''}
                                
                            </div>
                            <div className="limpio"><strong>Participación </strong>{song && song.perc_parti ? song.perc_parti : '-'}%</div>
                        </div>
                    </div>
                    <div className="row mb-1 justify-between w-85-f">
                        <div className="tt_ficha_sg w-100">Interprete</div>
                        <div className="limpio p-1 justify-between w-100">
                            <div className="limpio text-secondary">{song.interpreter ? song.interpreter.name : '-'}</div>
                        </div>
                    </div>
                    <div className="py-1 row w-85-f">
                        <div className="p-2 card-border row justify-between justify-items-center w-100">
                            <div className="col col-sm-12 text-left">
                                <strong className="limpio">
                                    <span className="phone-number">CONTRATO</span>
                                </strong>
                            </div>
                            <div className="col text-center sm-text-left">
                                <strong className="mx-1">
                                    <span className="phone-number">Incio</span>
                                </strong>
                                <span className="mx-1">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_start)}</span>
                            </div>
                            <div className="col text-right">
                                <strong className="mx-1">
                                    <span className="phone-number">Fin</span>
                                </strong>
                                <span className="mx-1">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_end)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="py-1 row w-85-f">
                        <div className="p-2 card-border row justify-between justify-items-center w-100">
                            <div className="col text-left">
                                <strong className="limpio">
                                    <span className="phone-number">SOCIEDAD AUTORAL</span>
                                </strong>
                            </div>
                            <div className="col text-right">
                                <strong className="mx-1">
                                    <span className="phone-number">{song.author_society ? song.author_society : ' - '}</span>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="py-2 border-bottom"></div>
                    <div className="py-2 row w-70"></div>
                    <div className="row w-70 justify-center">
                        <p className="fs-85 cl_gr text-center w-100">Donde nacen las obras maestras</p>
                        <a>songor.mx</a>
                    </div>
                    <div className="py-2 row w-100"></div>
                    <div className="row w-70 justify-center">
                        <strong className="fs-85 text-secondary">** Todos los derechos reservados © songor {new Date().getFullYear()} **</strong>
                    </div>
                    <div className="py-2 row w-100"></div>
                    <div className="py-2 row w-100"></div>
                </div>
            </div>
        </div>
    )
}